<template>
  <div>
    <Spinner />
    <b-spinner v-if="!unsettingPoints.data" class="spinner" label="Large Spinner" />

    <p v-if="unsettingPoints && unsettingPoints.meta" class="pagination__text">
      {{ unsettingPoints.meta.from }}-{{ unsettingPoints.meta.to }} из
      {{ unsettingPoints.meta.total }}
    </p>
    <button class="default-btn" @click="exportItems()">
        Экспорт
      </button>
    <b-pagination
      v-model="currentPage"
      :total-rows="unsettingPoints?.meta?.total"
      :per-page="perPage"
      aria-controls="my-table"
      :hide-goto-end-buttons="true"
      :limit="1"
      class="pogination"
      @input="changePage()"
    >
      <template #prev-text>
        <img
          class="pagination__btn-icon pagination__btn-icon_prev"
          src="@/assets/png/database-icon-active.png"
          alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>
    <div class="table">
      <b-table
        v-if="unsettingPoints.data"
        id="my-table"
        class="table__box"
        :borderless="true"
        :items="unsettingPoints.data"
        :fields="fields"
        small
      >
        <template #cell(comment)="data">
          <p>{{ data.item.comment }}</p>
        </template>
        <template #cell(count)="data">
          <p>{{ data.item.count }}</p>
        </template>
        <template #cell(created_at)="data">
          <p>{{ new Date(data.item.created_at).toLocaleDateString('ru-RU', {year: 'numeric',month: 'long',day: 'numeric'})  + ' ' + new Date(data.item.created_at).toLocaleTimeString() }}</p>
        </template>
        <template #cell(name)="data">
          <p>{{ data.item?.point?.name }}</p>
        </template>
        <template #cell(final_name)="data">
          <p>{{ data.item?.point?.final_name }}</p>
        </template>
        <template #cell(address)="data">
          <p>{{ data.item?.point?.address }}</p>
        </template>
        <template #cell(status)="data">
          <p>{{ data.item?.point?.status }}</p>
        </template>
        <template #cell(containers)="data">
          <div v-if="data.item?.point?.point_containers">
            <p v-for="container in data.item.point.point_containers" :key="container.id + ':' + container.code">
              {{ container.code }}
            </p>
          </div>
        </template>
        <template #cell(date)="data">
          <p>{{ new Date(data.item?.point?.date).toLocaleDateString('ru-RU', {year: 'numeric',month: 'long',day: 'numeric'}) + ' ' + new Date(data.item?.point?.date).toLocaleTimeString() }}</p>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Spinner from "@/components/spinner";

export default {
  components: { Spinner },
  data() {
    return {
      spinnerShow: true,
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "name",
          label: "Номер заказа",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        {
          key: "final_name",
          label: "Номер реализации",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        {
          key: "comment",
          label: "Комментарии",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        {
          key: "created_at",
          label: "Дата отложки/добавления",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        {
          key: "date",
          label: "Дата заказа",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        {
          key: "count",
          label: "Количество",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        {
          key: "address",
          label: "Адрес",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        {
          key: "containers",
          label: "Контейнеры",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        {
          key: "status",
          label: "Статус заказа",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        // {
        //   key: "download",
        //   label: "Скачать",
        //   sortable: true,
        //   isRowHeader: true,
        //   class: "tb-all tb-lg",
        // },
      ],
    };
  },
  computed: {
    rows() {
      if (this.unsettingPoints) {
        this.$emit("spinner");
        return this.unsettingPoints?.to;
      }
      return 0;
    },
    ...mapState(["unsettingPoints"]),
  },
  created() {
    if (this.$route.query.page != undefined) {
      this.currentPage = this.$route.query.page
    } else {
      this.$store.dispatch("getUnsettingPoints", {
        page: this.currentPage,
      })
    }

  },
  methods: {
    changePage() {
      const searchURL = new URL(window.location);
      searchURL.searchParams.set('page', this.currentPage);
      window.history.pushState({}, '', searchURL)
    },
    exportItems() {
     
        this.downloadLink = this.$url + "/api/v1/export_unsetting_points";
        window.open(this.downloadLink, "_blank");
    }
  },
  watch: {
    async currentPage() {
      await this.$store.dispatch("getUnsettingPoints", {
        page: this.currentPage,
      })
    },
  },
};
</script>

<style scoped>
.pogination {
  position: absolute;
  top: -82px;
  right: 0;
}
.pagination__btn-icon_prev {
  transform: rotate(180deg);
}
.report__export:hover {
  text-decoration: underline;
}
.pagination__text {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #306cdc;
  position: absolute;
  top: -9vh;
  right: 10vw;
}
.default-btn {
  position: absolute;
  top: -10vh;
  left: auto;
  right: 30vw;
}
</style>
